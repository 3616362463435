import React from 'react';
import { Card, Typography, CardHeader, Grid, CardContent, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';


export default function Page404() {
    const useStyles = makeStyles(theme => ({
        container: {
            padding: '8px',
        },
        title: {
            fontSize: "30px"
        },
    }));

    const classes = useStyles();

    return(
        <Container className={classes.container}>
            <Grid container spacing={20} direction="column" justify="center" alignItems="center">
                <Card>
                    <CardHeader title="404 Error!" />
                    <CardContent>
                        <Typography>Oops! There's nothing here.</Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Container>
    );
}