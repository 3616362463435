import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// import FranzHeadshot from '../static/images/franz-noel-tanglao.jpg';
import { Grid, Container, Card, CardContent, Typography } from '@material-ui/core';
import awsCloudCertificationImage from '../../static/images/aws-certified-cloud-practitioner.png';
import csmImage from '../../static/images/seal-csm.png';
import awsSolutionsArchitectAssociateImage from '../../static/images/aws-certified-solutions-architect-associate.png';
import awsDeveloperAssociateImage from '../../static/images/aws-developer-associate.png';

export default function Profile(props) {
  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      '& > *': {
      margin: theme.spacing(1),
      },
    },
    container: {
      padding: '10px',
    },
    title: {
      fontSize: "30px"
    },
  }));

  const classes = useStyles();

  const certifications = [
    {
      name: 'Certified AWS Cloud Certification',
      image: awsCloudCertificationImage,
      style: { width: 150, height: 150, cursor: 'pointer' },
      link: 'https://www.credly.com/badges/edb6f265-38e5-4769-91c9-89633e7db84b/public_url'
    },
    {
      name: 'Certified Scrum Master',
      image: csmImage,
      style: { width: 150, height: 150, cursor: 'pointer' },
      link: 'https://bcert.me/sozgtawvn'
    },
    {
      name: 'Certified AWS Solutions Architect - Associate',
      image: awsSolutionsArchitectAssociateImage,
      style: { width: 150, height: 150, cursor: 'pointer' },
      link: 'https://www.credly.com/earner/earned/badge/e154c78f-f1b0-42ac-9072-04aa6ef59120'
    },
    {
      name: 'Certified Developer - Associate',
      image: awsDeveloperAssociateImage,
      style: { width: 150, height: 150, cursor: 'pointer' },
      link: 'https://www.credly.com/earner/earned/badge/e154c78f-f1b0-42ac-9072-04aa6ef59120'
    },
  ];

  return (
    <Container className={classes.container}>
      <h1 className={classes.title}>{props.title}</h1>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Card spacing={2}>
              <CardContent>
                  <Typography variant="h6">About</Typography>
                  <p variant="body2">As a seasoned Software Engineer with years of full-stack development experience, I have honed my skills across various domains, from backend programming and frontend development to team management and training. My expertise spans a wide range of programming languages, with a strong emphasis on object-oriented programming, data structures, and design patterns.</p>
                  <p variant="body2">In the realm of server and systems management, I have successfully leveraged cloud platforms, written Bash scripts, and developed proprietary applications for private platforms. My proficiency extends to working with diverse databases, including both SQL and NoSQL, as well as adapting to existing frameworks or creating custom ones.</p>
                  <p variant="body2">My project management skills encompass the efficient use of Jira Agile, implementation of Jira and Github automation, and the application of SCRUM methodology to streamline team performance and ensure timely deliveries.</p>
                  <p variant="body2">In addition, I possess a strong understanding of network protocols, system architecture security, and command-line tools. My experience in scaling servers and networks is complemented by a passion for meticulous documentation and thorough explanations of my learnings.</p>
                  <p variant="body2">As a multifaceted professional, I am well-equipped to tackle complex projects and drive innovation in any team I join.</p>
              </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12}>
          <Card spacing={2}>
            <CardContent>
              <Grid container>
                <Grid item sm={8}>
                  <div>
                    <Typography variant="h6">Software Experiences</Typography>
                    <ul>
                        <li><b>Languages:</b> Python, Java, PHP, HTML,  JavaScript, MySQL, jQuery</li>
                        <li><b>E-Commerce Software:</b> Magento, XCart, MiRetail, and ATG eCommerce</li>
                        <li><b>CMS:</b> Wordpress, or custom made using PHP/Python</li>
                        <li><b>Protocols:</b> DNS ADDRESS, IP ADDRESS, HTTP, FTP, TCP, UDP, WS, etc.</li>
                        <li><b>Package Managers:</b> NPM, Linux Package Managers, PIP (Python)</li>
                        <li><b>Databases:</b> MySQL, PgSQL, Google Datastore, MongoDB, Google BigQuery</li>
                        <li><b>Servers:</b> Google Cloud, AWS, NodeJs, Apache, AWS S3, AWS SNS, and Firebase</li>
                        <li><b>Repositories:</b> SVN, Mercurial, Git, Jira (repository controller)</li>
                        <li><b>Pipeline:</b>Pipeline: Jenkins, Artifactory</li>
                        <li><b>Operating Systems:</b> Linux/Ubuntu (Bash), Windows, and Mac</li>
                        <li><b>Graphic Design:</b>  Gimp and Adobe Photoshop</li>
                        <li><b>IDE:</b> Eclipse, Sublime Text 3, and Notepad++, XCode, Visual Studio</li>
                        <li><b>Design Patterns Advocate:</b> Practices Model View Controller (MVC), Model View Presenter (MVP), OOP, etc.</li>
                        <li><b>Project Management Tools:</b> Jira Agile</li>
                        <li><b>Web/Application Tools:</b> Bootstrap, Semantic UI, Zurb Foundation, Webix, Angular, ReactJS</li>
                    </ul>
                  </div>
                </Grid>
                <Grid item sm={4}>
                  <div>
                    <Typography variant="h6">Education</Typography>
                    <Typography variant="h6">University of Phoenix 2015</Typography>
                    <Typography variant="body2" paragraph>Degree: Masters in Information Systems (Graduated)</Typography>
                    <Typography variant="h6">University of St. La Salle 2003</Typography>
                    <Typography variant="body2" paragraph>Degree: Bachelor of Science in Computer Science (Graduated)</Typography>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12}>
          <Card spacing={2}>
            <CardContent>
              <Grid container>
                <Grid item sm={12}>
                  <Typography variant="h6">Certifications</Typography>
                  {certifications.map(({ name, image, style, link}) => {
                    return (
                      <img
                        alt={name}
                        src={image}
                        style={style}
                        onClick={() => window.open(link, '_blank')}
                      />
                    )
                  })}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>        
      </Grid>
    </Container>
  );
}
