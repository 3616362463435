import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MainToolbar from './components/MainToolbar';
import Profile from './containers/Profile/index';
import Page404 from './containers/Page404';

import './App.css';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Franz Noel Tanglao",
    };
  }

  render() {
    return (
      <div>
        <MainToolbar title={this.state.title}></MainToolbar>
        <Router>
          <Switch>
            <Route exact path="/" render={(props) => <Profile {...props} title={this.state.title} />} />
            <Route path="*" component={Page404} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
